import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="gpt3__header section__padding" id="home" data-aos="fade-down">
      <div className="gpt3__header-content">
        <h1 className="gradient__text animate__animated animate__fadeInUp">Let&apos;s Build Something amazing with your idea</h1>
        <p className="animate__animated animate__fadeInUp">With our amazing team we have been building software and web solutions for years. We’re constantly refining our product. Adding new features. Working to help your section business grow.</p>

        <div className="gpt3__header-content__input">
          {/* <input type="email" placeholder="Your Email Address" /> */}
          <Link to="/contact"><button type="button">Get Started</button></Link>
        </div>

        {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
      </div>

      <div className="gpt3__header-image">
        <img src={ai} />
      </div>
    </div>
  );
};

export default Header;
