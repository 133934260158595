import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">What can we build<br />for you?</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="" text="Custom Software" url="/services#software" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="" text="Webiste Design" url="/services#website" />
        <Article imgUrl={blog03} date="" text="Mobile App" url="/services#mobile" />
        <Article imgUrl={blog04} date="" text="Tailor-Made CRM" url="/services#crm" />
        <Article imgUrl={blog05} date="" text="Copy Writing" url="/services#copy" />
      </div>
    </div>
  </div>
);

export default Blog;
