import React from 'react';
import { dropbox, shopify, meta, discord, adobe } from './imports';
import './brand.css';

const Brand = () => (
  <div className="gpt3__brand section__padding">
    <div>
      <img src={meta} />
    </div>
    <div>
      <img src={discord} />
    </div>
    <div>
      <img src={adobe} />
    </div>
    <div>
      <img src={dropbox} />
    </div>
    <div>
      <img src={shopify} />
    </div>
  </div>
);

export default Brand;
