import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';

import { Features } from '../../containers';
import office1 from '../../assets/office1.jpg';
import office2 from '../../assets/office2.jpg';
import office4 from '../../assets/office4.jpg';

const Aboutus = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="aboutus__section-container__section">
      <div className="aboutus__section-container__section__who">
        <div className="aboutus__section-container__section-title" data-aos="fade-up">
          <h2>Who we are?</h2>
        </div>
        <div className="aboutus__section-container__section-text" data-aos="fade-up">
          <p>We are a software company specializing in the development of complex software, web solutions and mobile applications. Our company was founded in 2018 by a group of successful experts in the field of various sectors.
            Our portfolio consists of various projects in the fields of medicine, law, banking, e-commerce, hospitality and many other fields.
            In addition to all this, we perform constant monitoring, react to incidents immediately and take care of your business and data. We provide a customized infrastructure with the applications we develop, but we also offer it as a standalone service.
            We have the technical expertise and business acumen to ensure your requirements are accurately understood so that we can propose process improvements that fix legacy issues.
          </p>
        </div>

        <div className="aboutus__container_images" data-aos="fade-up">
          <img src={office1} />
          <img src={office2} />
          <img src={office4} />
        </div>

      </div>
      <div className="aboutus__section-container__section-mission-vision" data-aos="fade-up">
        <div className="aboutus__section-container__section-mission-vision__card">
          <h4>Mission</h4>
          <p className="text__decoration">Exceed client&apos;s expectations by going beyond software to provide best Web solutions that transform data into knowledge, enabling them to solve their problems.</p>
        </div>
        <div className="aboutus__section-container__section-mission-vision__card">
          <h4>Vision</h4>
          <p className="text__decoration">Simplifying business processes and challenging the industry status quo through IT solutions designed to answer client requirements.</p>
        </div>
      </div>
      <div data-aos="fade-up">
        <Features />
      </div>
      <div className="aboutus__section-container__section__who">
        <div className="aboutus__section-container__section-title" data-aos="fade-up">
          <h2>How we operate?</h2>
        </div>
        <div className="aboutus__section-container__section-text" data-aos="fade-up">
          <p>You contact us, and we make an online appointment for you. We ask you many questions, what is your idea, your goal, your business.
            After this you will receive a free advice and recommendation.
            Based on all your answers, we get to work and build the software, application, website, etc. that you want.
          </p>
        </div>

      </div>
    </div>
  );
};

export default Aboutus;
