import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

import './services.css';

import javascript from '../../assets/technologies/javascript.svg';
import php from '../../assets/technologies/php.svg';
import laravel from '../../assets/technologies/laravel.svg';
import docker from '../../assets/technologies/docker.svg';
import mongodb from '../../assets/technologies/mongodb.svg';
import react from '../../assets/technologies/react.svg';
import elasticsearch from '../../assets/technologies/elasticsearch.svg';
import sql from '../../assets/technologies/sql-server.svg';
import java from '../../assets/technologies/java.svg';
import wordpress from '../../assets/technologies/wordpress.png';
import nodejs from '../../assets/technologies/nodejs.png';

export const Services = () => {
  const history = useHistory();

  useEffect(() => {
    if (history.location.hash) {
      const getMeTo = document.querySelector(history.location.hash);
      getMeTo.scrollIntoView({ behavior: 'smooth' }, true);
    }
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="services__section-container__section-services">
      <div className="services__section-container__section-services__card" id="software" data-aos="fade-right" data-aos-easing="ease-in-sine">
        <h4>Custom Software</h4>
        <p className="text__decoration">When organizations and individuals need applications with unique features and functionality, they turn to us to design and create custom solutions for them. We create the software from start to finish, designed and built in accordance with all the requirements of our customers.
          Software such as bank payment systems, employee management software, clinic software, etc.
        </p>
      </div>
      <div className="services__section-container__section-services__card" id="website" data-aos="fade-left" data-aos-easing="ease-in-sine">
        <h4>Webiste Design</h4>
        <p className="text__decoration">Web design encompasses many different skills and disciplines in the production and maintenance of websites. The different areas of web design include web graphic design, user interface design, authoring, including standardised code and proprietary software, user experience design, and search engine. We can also create all kinds of web applications of like e-commerce websites.</p>
      </div>
      <div className="services__section-container__section-services__card" id="mobile" data-aos="fade-right" data-aos-easing="ease-in-sine">
        <h4>Mobile App</h4>
        <p className="text__decoration">A mobile app is a software application developed specifically for use on small, wireless computing devices, such as smartphones and tablets, rather than desktop or laptop computers.</p>
      </div>
      <div className="services__section-container__section-services__card" id="crm" data-aos="fade-left" data-aos-easing="ease-in-sine">
        <h4>Tailor-Made CRM</h4>
        <p className="text__decoration">SCRM (Customer Relationship Management) software is bespoke custom built business software that is specifically designed and tailored to perfectly suit your individual business needs.</p>
      </div>
      <div className="services__section-container__section-services__card" id="copy" data-aos="fade-right" data-aos-easing="ease-in-sine">
        <h4>Copy Writing</h4>
        <p className="text__decoration">Copywriting is the act or occupation of writing text for the purpose of advertising or other forms of marketing. The product, called copy or sales copy, is written content that aims to increase brand awareness and ultimately persuade a person or group to take a particular action.</p>
      </div>

      <div className="services__section-container__section-services">
        <div className="gpt3__possibility-content">
          <h4>Request Early Access to Get Started</h4>
          <h1 className="gradient__text">
            Тechnology Stack
          </h1>
          <div className="technologies-used__container">
            <div className="technologies-used__inner">
              <img src={javascript} />
              <p>JavaScript</p>
            </div>
            <div className="technologies-used__inner">
              <img src={php} />
              <p>PHP</p>
            </div>
            <div className="technologies-used__inner">
              <img src={laravel} />
              <p>Laravel</p>
            </div>
            <div className="technologies-used__inner">
              <img src={docker} />
              <p>Docker</p>
            </div>
            <div className="technologies-used__inner">
              <img src={mongodb} />
              <p>Mongodb</p>
            </div>
            <div className="technologies-used__inner">
              <img src={react} />
              <p>React</p>
            </div>
            <div className="technologies-used__inner">
              <img src={elasticsearch} />
              <p>Elasticsearch</p>
            </div>
            <div className="technologies-used__inner">
              <img src={sql} />
              <p>SQL-server</p>
            </div>
            <div className="technologies-used__inner">
              <img src={java} />
              <p>Java</p>
            </div>
            <div className="technologies-used__inner">
              <img src={wordpress} />
              <p>Wordpress</p>
            </div>
            <div className="technologies-used__inner">
              <img src={nodejs} />
              <p>Nodejs</p>
            </div>
          </div>
          <h4>Request Early Access to Get Started</h4>
        </div>
      </div>
    </div>
  );
};

export default Services;
