import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Footer, Blog, Possibility, WhatGPT3, Header } from './containers';
import { CTA, Brand, Navbar, Services } from './components';

import './App.css';
import Aboutus from './components/aboutus/Aboutus';
import Contact from './components/contact/Contact';

const App = () => (
  <div className="App">
    <Switch>
      <Route path="/aboutus" exact>
        <Navbar />
        <Aboutus />
        <Footer />
      </Route>
      <Route path="/services" exact>
        <Navbar />
        <Services />
        <Footer />
      </Route>
      {/* <Route path="/joinus" exact>
        <Navbar />
        <Joinus />
        <Footer />
      </Route> */}
      <Route path="/contact" exact>
        <Navbar />
        <Contact />
        <Footer />
      </Route>
      <Route path="/">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <Brand />
        <WhatGPT3 />
        {/* <Features /> */}
        <Possibility />
        <CTA />
        <Blog />
        <Footer />
      </Route>
    </Switch>
  </div>
);

export default App;
