import React, { useState, useEffect } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { NavLink, Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import logo from '../../assets/HighLogo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="gpt3__navbar" data-aos="fade-down">
      <div className="gpt3__navbar-links">
        <Link to="/" className="gpt3__navbar-links_logo">
          <img src={logo} />
        </Link>
        <div className="gpt3__navbar-links_container">
          <p>
            <NavLink activeClassName="active" to="/home">
              Home
            </NavLink>
          </p>
          <p>
            <NavLink activeClassName="active" to="/aboutus">
              About Us
            </NavLink>
          </p>
          <p>
            <NavLink activeClassName="active" to="/services">
              Services
            </NavLink>
          </p>
          {/* <p>
            <NavLink activeClassName="active" to="/joinus">
              Join Us
            </NavLink>
          </p> */}
          <p>
            <NavLink activeClassName="active" to="/contact">
              Contact
            </NavLink>
          </p>
        </div>
      </div>
      {/* <div className="gpt3__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div> */}
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <NavLink activeClassName="active" to="/home">
                  Home
                </NavLink>
              </p>
              <p>
                <NavLink activeClassName="active" to="/aboutus">
                  About Us
                </NavLink>
              </p>
              <p>
                <NavLink activeClassName="active" to="/services">
                  Services
                </NavLink>
              </p>
              {/* <p>
                <NavLink activeClassName="active" to="/joinus">
                  Join Us
                </NavLink>
              </p> */}
              <p>
                <NavLink activeClassName="active" to="/contact">
                  Contact
                </NavLink>
              </p>
            </div>
            {/* <div className="gpt3__navbar-menu_container-links-sign">
            <p>Sign in</p>
            <button type="button">Sign up</button>
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
