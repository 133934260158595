import React from 'react';
import { Link } from 'react-router-dom';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Request Early Access to Get Started</p>
      <h3>Talk to us and we will make your business idea come true.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <Link to="/contact"><button type="button">Get Started</button></Link>
    </div>
  </div>
);

export default CTA;
