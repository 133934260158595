import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

import './contact.css';

const Contact = () => {
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  window.scrollTo(0, 0);

  const submitHandler = (event) => {
    event.preventDefault();

    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const message = document.getElementById('message').value;

    if (name.length < 1) {
      setErrorName('Name is required');
      return;
    }
    if (email.length < 1) {
      setErrorEmail('Email is required');
      return;
    }
    if (message.length < 1) {
      setErrorMessage('Text is required');
      return;
    }
    const data = {
      name,
      email,
      message,
    };

    fetch(
      'https://pandora-v-f1408-default-rtdb.europe-west1.firebasedatabase.app/emails.json', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'aplication/json',
        },
      },
    ).then((response) => response.json())
      .then((datar) => {
        console.log('Message send', datar);
        setErrorName('');
        setErrorEmail('');
        setErrorMessage('');
        setSuccess('Message has been send!');
      });
  };

  return (
    <div className="contact__section-container__section-contact">
      <div className="contact__section-container__section-contact__card" data-aos="fade-up">
        <h4>Stip</h4>
        <p className="text__decoration"><LocationOnIcon />Marsal Tito b.b, Shtip, 1000 Shtip, North Macedonia</p>
        <p className="text__decoration"><LocalPhoneIcon />+389 76 220 171</p>
        <p className="text__decoration"><EmailIcon />contact@pandora-v.com</p>
      </div>
      <form onSubmit={submitHandler} id="emailForm" className="contact__section-container__section-contact__card" data-aos="fade-up">
        <h4>Send us a message</h4>
        <div className="container_message_input">
          <label htmlFor="name" className="text__decoration">Name*</label><br />
          <input className="message_input" id="name" type="text" />
          <div className="errorMessage">{errorName}</div>
        </div>
        <div className="container_message_input">
          <label htmlFor="email" className="text__decoration">Email*</label><br />
          <input className="message_input" id="email" type="email" />
          <div className="errorMessage">{errorEmail}</div>
        </div>
        <div className="container_message_input">
          <label htmlFor="message" className="text__decoration">Your message*</label><br />
          <textarea className="message_input" id="message" type="textarea" />
          <div className="errorMessage">{errorMessage}</div>
        </div>
        <div className="successMessage">{success}</div>
        <div className="gpt3__header-content__input">
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
