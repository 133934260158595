import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import javascript from '../../assets/technologies/javascript.svg';
import php from '../../assets/technologies/php.svg';
import laravel from '../../assets/technologies/laravel.svg';
import docker from '../../assets/technologies/docker.svg';
import mongodb from '../../assets/technologies/mongodb.svg';
import react from '../../assets/technologies/react.svg';
import elasticsearch from '../../assets/technologies/elasticsearch.svg';
import sql from '../../assets/technologies/sql-server.svg';
import java from '../../assets/technologies/java.svg';
import wordpress from '../../assets/technologies/wordpress.png';
import nodejs from '../../assets/technologies/nodejs.png';

const Possibility = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="gpt3__possibility section__padding" id="possibility">
      <div className="gpt3__possibility-image">
        <img src={possibilityImage} alt="possibility" data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
      </div>
      <div className="gpt3__possibility-content">
        <h4>Request Early Access to Get Started</h4>
        <h1 className="gradient__text">
          Тechnology Stack
        </h1>
        <div className="technologies-used__container">
          <div className="technologies-used__inner">
            <img src={javascript} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>JavaScript</p>
          </div>
          <div className="technologies-used__inner">
            <img src={php} data-aos="flip-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>PHP</p>
          </div>
          <div className="technologies-used__inner">
            <img src={laravel} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Laravel</p>
          </div>
          <div className="technologies-used__inner">
            <img src={docker} data-aos="flip-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Docker</p>
          </div>
          <div className="technologies-used__inner">
            <img src={mongodb} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Mongodb</p>
          </div>
          <div className="technologies-used__inner">
            <img src={react} data-aos="flip-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>React</p>
          </div>
          <div className="technologies-used__inner">
            <img src={elasticsearch} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Elasticsearch</p>
          </div>
          <div className="technologies-used__inner">
            <img src={sql} data-aos="flip-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>SQL-server</p>
          </div>
          <div className="technologies-used__inner">
            <img src={java} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Java</p>
          </div>
          <div className="technologies-used__inner">
            <img src={wordpress} data-aos="flip-right" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Wordpress</p>
          </div>
          <div className="technologies-used__inner">
            <img src={nodejs} data-aos="flip-left" data-aos-offset="500" data-aos-easing="ease-in-sine" />
            <p>Nodejs</p>
          </div>
        </div>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  );
};

export default Possibility;
