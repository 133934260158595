import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Nurture',
    text: 'We value potential and help each other develop our skills and kindle our passion',
  },
  {
    title: 'Diversity',
    text: 'We are open to and benefit from diverse experiences, genders and insights',
  },
  {
    title: 'Respect',
    text: 'Each of us is equally accepted and respected in their difference',
  },
  {
    title: 'Flexibility',
    text: 'Our flexibility allows us to integrate our solutions into the custom environment of our customers, while being able to react effectively as their needs evolve',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Our values help us achieve our goals through a successful balance between innovation, continuous improvement, and team spirit</h1>
      {/* <p>Request Early Access to Get Started</p> */}
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
