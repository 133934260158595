import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3" data-aos="fade-up">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="What can we do for you?" text="We are driven by the desire to run a people-centred company with a highly motivated and involved team, working with mutual respect and trust. We aim for long term results and strong relationships with the brands and companies we work and collaborate with." />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
        {/* <p>Explore the Library</p> */}
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="Digital Solutions" text="Pandora V develops web and mobile applications. Our team has extensive knowledge in various technologies, which makes us a fully eligible partner for complex integrations." />
        <Feature title="Managed Services" text="We perform constant monitoring, react to incidents immediately and take care of your business and data. We provide a customized infrastructure with the applications we develop but we also offer it as a standalone service." />
        <Feature title="Business Analysis" text="We have the technical expertise and business acumen to ensure your requirements are accurately understood so that we can propose process improvements that fix legacy issues." />
      </div>
    </div>
  );
};

export default WhatGPT3;
