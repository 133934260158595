import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/HighLogo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Have and idea? Let&apos;s talk about it!</h1>
    </div>

    <div className="gpt3__footer-btn">
      <Link to="/contact"><p>Request Early Access</p></Link>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="gpt3_logo" />
        <p>Marsal Tito b.b, Shtip, 1000 Shtip, North Macedonia, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p><a target="_blank" href="https://www.linkedin.com/company/pandora-v" rel="noreferrer">Linkedin</a></p>
        <p>Instagram</p>
        {/* <p>Facebook</p> */}
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Marsal Tito b.b, Shtip, 1000 Shtip, North Macedonia</p>
        <p>+389 76 220 171</p>
        <p>contact@pandora-v.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2019 Pandora-V. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
